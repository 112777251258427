import { useEffect, useRef, useState } from 'react';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Style
import PopupSubscribeStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import FormItem from '@/components/global/Form/FormItem';
import FormInput from '@/components/global/Form/FormInput';
import FormContainer from '@/components/global/Form/FormContainer';
import { useSearchParams } from 'next/navigation'
import { isEmailInvalid } from '@/helpers';

const PopupSubscribe = ({ translate, subscribe, downloadSubscribe, closePopup, title, description, tag, isDownloadPopup = false }) => {

    const searchParams = useSearchParams()
    const search = searchParams.toString()
    //! State
    const [values, setValues] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const formRef = useRef();
    //! Handle Finish
    const handleFinish = (values) => {
        if (!isSuccess) {
            window.dataLayer = window.dataLayer || [];

            return (isDownloadPopup 
                ? downloadSubscribe({ ...values, tag })
                : subscribe({ ...values })
            ).then(() => {
                window.dataLayer.push({
                    'event': 'email',
                });
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                    closePopup();
                }, 60000);
            });
        }
    };


    useEffect(() => {
        if (values?.email) {
            formRef.current?.setFields([
                {
                  name: 'email',
                  errors: [],
                },
            ])
        }
    }, [values])

    return (
        <PopupSubscribeStyle>
            <Text
                className={'h5 font-argent letter-spacing-h5 white-1000 subscribe-title'}
                text={title || 'subscribeToAnalytics'}
            />
            <Text
                className={'p p6 font-avenir-regular white-1000 subscribe-description'}
                text={description || 'popupDescription'}
            />
            <FormContainer ref={formRef} onChange={setValues}  onFinish={handleFinish} initialValues={{ ["utm"]: search ? search : "" }} >
                <FormItem
                    name={'email'}
                    validationType={'email'}>
                    <FormInput placeholder={translate('emailPlaceholder')} />
                </FormItem>
                <FormItem
                    name={'utm'}
                    required={false}>
                    <FormInput type="hidden" />
                </FormItem>
                <FormItem
                    name={'tag'}
                    required={false}>
                    <FormInput type="hidden" />
                </FormItem>
                {isSuccess ? (
                    <Text
                        className={'p p5 font-avenir-regular success-color subscribe-text'}
                        text={'subscribeAnalyticsText'}
                    />
                ) : (
                    <Button
                        onClick={e => {
                            if (values?.email) {
                                const isValid = (!isEmailInvalid(values.email));
                                if (!isValid) {
                                    formRef.current?.setFields([
                                        {
                                          name: 'email',
                                          errors: [translate('emailInvalidAnalytics')],
                                        },
                                     ])
                                    e.preventDefault()
                                    return;
                                }
                                return;
                            } else {
                                e.preventDefault()
                                formRef.current?.setFields([
                                    {
                                      name: 'email',
                                      errors: [translate('emailRequired')],
                                    },
                                ])
                            }
                        }}
                        className={'primary inverse subscribe-button'}
                        text={'submit'}
                        type={'submit'}
                    />
                )}
            </FormContainer>
        </PopupSubscribeStyle>
    );
};

export default withUIContext(withDataContext(withLanguageContext(PopupSubscribe, ['translate']), ['subscribe', 'downloadSubscribe']), ['closePopup']);
