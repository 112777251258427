import { forwardRef, useRef, useImperativeHandle } from 'react';

//* Style
import FileSectionStyle from './style';

//* Components
import Image from '../Image';
import Video from '../Video';

const FileSection = forwardRef(({ file, objectFit = 'cover', className, priority = false }, ref) => {
	//! Ref
	const fileRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => fileRef.current, []);

	return (
		<FileSectionStyle className={`file-section ${className || ''}`}>
			<div className='file-container'>
				{file.type === 'image' ? (
					<Image
						ref={fileRef}
						src={file.src}
						alt={file.alt}
						priority={priority}
						className={objectFit}
					/>
				) : (
					<Video
						loop
						autoPlay
						ref={fileRef}
						src={file.src}
					/>
				)}
			</div>
		</FileSectionStyle>
	);
});

FileSection.displayName = 'FileSection';

export default FileSection;
