'use client';

import { useCallback, useEffect, useRef } from 'react';
import { gsap, Power2 } from 'gsap';
import { usePathname, useParams } from 'next/navigation';

//* Style
import StatisticsSectionStyle from './style';

//* Helpers
import { useActiveSection } from '@/helpers';

//* HOC's
import { withDataContext, withUIContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import PopupSubscribe from '@/components/global/PopupSubscribe';

const StatisticsSection = ({ getGlobalData, winWidth, isDark, openPopup }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Refs
	const statisticsSectionRef = useRef([]);
	const stContentTitleRef = useRef([]);
	const stContentDescRef = useRef([]);
	const stContentBtnRef = useRef([]);

	const stItemTitleRefs = useRef([]);
	const stItemDescRefs = useRef([]);
	const stItemLineRefs = useRef([]);
	const stFirstItemTitleRef = useRef([]);
	const stFirstItemDescRef = useRef([]);
	const stFirstItemLineRef = useRef([]);
	const stItemVerticalLineRef = useRef([]);

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Active Section
	const activeSection = useActiveSection(statisticsSectionRef, 100);

	//! Handle Button Popup
	const handleBtnPopup = useCallback(() => {
		openPopup(<PopupSubscribe />);
	}, []);

	//! Left Part Animations
	useEffect(() => {
		if (activeSection) {
			gsap.to(stContentTitleRef.current, { scale: 1, duration: 0.7, opacity: 1, delay: 0.1, ease: Power2.easeOut });

			gsap.to(stContentDescRef.current, { opacity: 1, duration: 0.7, delay: 0.3, ease: Power2.easeOut });

			gsap.to(stContentBtnRef.current, { scale: 1, duration: 0.7, delay: 0.5, ease: Power2.easeOut });
		} else {
			gsap.to(stContentTitleRef.current, { scale: winWidth >= 768 ? 0.9 : 0.9, duration: 0, opacity: 0, delay: 0 });

			gsap.to(stContentDescRef.current, { opacity: 0, duration: 0, delay: 0 });

			gsap.to(stContentBtnRef.current, { scale: 0, duration: 0, delay: 0 });

			gsap.killTweensOf([stContentTitleRef.current, stContentDescRef.current, stContentBtnRef.current]);
		}
	}, [pathname, params.lang, activeSection, winWidth]);

	//! Right Part Animations
	useEffect(() => {
		if (activeSection) {
			gsap.to([stFirstItemTitleRef.current, ...stItemTitleRefs.current], { scale: 1, duration: 0.7, opacity: 1, delay: 0.1, stagger: 0.1, ease: Power2.easeOut });

			gsap.to([stFirstItemDescRef.current, ...stItemDescRefs.current], { opacity: 1, duration: 0.7, delay: 0.3, stagger: 0.1, ease: Power2.easeOut });

			gsap.to([stFirstItemLineRef.current, ...stItemLineRefs.current], {
				width: '100%',
				duration: 0.7,
				delay: 0.5,
				stagger: 0.1,
				ease: Power2.easeOut,
			});

			gsap.to(stItemVerticalLineRef.current, {
				height: '100%',
				duration: 0.7,
				delay: 0.7,
				ease: Power2.easeOut,
			});
		} else {
			gsap.to([stFirstItemTitleRef.current, ...stItemTitleRefs.current], {
				scale: winWidth >= 768 ? 0.9 : 0.9,
				duration: 0,
				opacity: 0,
				delay: 0,
				stagger: 0,
			});

			gsap.to([stFirstItemDescRef.current, ...stItemDescRefs.current], {
				opacity: 0,
				duration: 0,
				delay: 0,
				stagger: 0,
			});

			gsap.to([stFirstItemLineRef.current, ...stItemLineRefs.current], {
				width: 0,
				duration: 0,
				delay: 0,
				stagger: 0,
			});

			gsap.to(stItemVerticalLineRef.current, {
				height: 0,
				duration: 0,
				delay: 0,
			});
		}
	}, [pathname, params.lang, activeSection, winWidth]);

	return (
		<StatisticsSectionStyle className={`${isDark ? 'dark' : ''}`}>
			<Container
				row
				isSection={!isDark}
				ref={statisticsSectionRef}>
				<div className={`statistics-content-wrapper col-5 col-t-12`}>
					<Text
						ref={stContentTitleRef}
						className={`st-content-title h3 font-argent royal-blue-1000 letter-spacing-h3 capitalize`}>
						{globalData.statistics.title}
					</Text>

					<Text
						ref={stContentDescRef}
						className={`st-content-description p p5 font-avenir-regular royal-blue-1000`}>
						{globalData.statistics.description}
					</Text>

					<div
						ref={stContentBtnRef}
						className={`st-content-btn-wrapper`}>
						<Button
							className={`primary inverse`}
							onClick={handleBtnPopup}
							text={'subscribeToAnalytics'}
						/>
					</div>
				</div>

				<div className={`statistics-empty-col col-1 col-t-12`} />

				<div className={`statistics-analytics-wrapper col-6 col-t-12`}>
					<div className={`st-analytics-inner-wrapper`}>
						<div className={`st-item-wrapper first-item`}>
							<div className={`st-item-inner-wrapper`}>
								<Text
									ref={stFirstItemTitleRef}
									className={`st-item-title font-argent royal-blue-1000 h1 letter-spacing-h1`}>
									{globalData.statistics.items[0].title}
								</Text>
								<Text
									ref={stFirstItemDescRef}
									className={`st-item-description p p5 font-avenir-regular royal-blue-1000`}>
									{globalData.statistics.items[0].description}
								</Text>
							</div>

							<div
								ref={stFirstItemLineRef}
								className={`st-first-item-absolute-horizontal-line`}
							/>
						</div>

						<div className={`st-items-container`}>
							{globalData.statistics.items.map((statistic, index) => {
								return index > 0 ? (
									<div
										key={index}
										className={`st-item-wrapper`}>
										<div className={`st-item-inner-wrapper`}>
											<Text
												ref={(ref) => ref && (stItemTitleRefs.current[index] = ref)}
												className={`st-item-title font-argent royal-blue-1000 h4 letter-spacing-h4`}>
												{statistic.title}
											</Text>
											<Text
												ref={(ref) => ref && (stItemDescRefs.current[index] = ref)}
												className={`st-item-description p p6 font-avenir-regular royal-blue-1000`}>
												{statistic.description}
											</Text>
										</div>

										{index > 1 ? (
											<div
												ref={(ref) => ref && (stItemLineRefs.current[index] = ref)}
												className={`st-item-absolute-horizontal-line`}
											/>
										) : null}
									</div>
								) : null;
							})}

							<div
								ref={stItemVerticalLineRef}
								className={`st-item-absolute-vertical-line`}
							/>
						</div>
					</div>
				</div>
			</Container>
		</StatisticsSectionStyle>
	);
};

export default withUIContext(withDataContext(StatisticsSection, ['getGlobalData']), ['winWidth', 'openPopup']);
