'use client';

import { useEffect, useRef } from 'react';
import { gsap, Power2 } from 'gsap';
import { usePathname, useParams } from 'next/navigation';

//* HOC's
import { withDataContext, withUIContext } from '@/context';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Style
import CallToActionSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import PopupSubscribePlan from '@/components/global/PopupSubscribePlan';

const CallToActionSection = ({ getGlobalData, winWidth, openPopup }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Refs
	const callToActionSectionRef = useRef([]);
	const callSectionTitleRef = useRef([]);
	const callSectionDescRef = useRef([]);
	const callSectionBtnRef = useRef([]);

	//! Active Section
	const activeSection = useActiveSection(callToActionSectionRef, 100);

	//! Animations
	useEffect(() => {
		if (activeSection) {
			gsap.to(callSectionTitleRef.current, { scale: 1, duration: 0.7, opacity: 1, delay: 0.1, ease: Power2.easeOut });

			gsap.to(callSectionDescRef.current, { opacity: 1, duration: 0.7, delay: 0.3, ease: Power2.easeOut });

			gsap.to(callSectionBtnRef.current, { scale: 1, duration: 0.7, delay: 0.5, ease: Power2.easeOut });
		} else {
			gsap.to(callSectionTitleRef.current, { scale: winWidth >= 768 ? 0.9 : 0.9, duration: 0, opacity: 0, delay: 0 });

			gsap.to(callSectionDescRef.current, { opacity: 0, duration: 0, delay: 0 });

			gsap.to(callSectionBtnRef.current, { scale: 0, duration: 0, delay: 0 });

			gsap.killTweensOf([callSectionTitleRef.current, callSectionDescRef.current, callSectionBtnRef.current]);
		}
	}, [pathname, params.lang, activeSection, winWidth]);

	return (
		<CallToActionSectionStyle>
			<Container
				row
				isSection
				ref={callToActionSectionRef}>
				<div className={`call-empty-col col-1 col-t-12`} />

				<div className={`call-section-title col-10 col-t-12`}>
					<Text
						ref={callSectionTitleRef}
						className={`h3 font-argent royal-blue-1000 letter-spacing-h3 capitalize text-center`}>
						{globalData.call_to_action.title}
					</Text>
				</div>

				<div className={`call-empty-col col-1 col-t-12`} />

				<div className={`call-empty-col col-2 col-t-12`} />

				<div className={`call-section-description col-8 col-t-12`}>
					<Text
						ref={callSectionDescRef}
						className={`p p6 font-avenir-regular royal-blue-1000 text-center`}>
						{globalData.call_to_action.description}
					</Text>
				</div>

				<div className={`call-empty-col col-2 col-t-12`} />

				<div className={`col-12 call-btn-wrapper`}>
					<div
						ref={callSectionBtnRef}
						className={`call-btn-inner-wrapper`}>
						{/* <Button
							url={'/pricing'}
							className={`primary`}
							text={`ConfigsPricing`}
							// onClick={() => openPopup(<PopupSubscribePlan />)}
						/> */}
					</div>
				</div>
			</Container>
		</CallToActionSectionStyle>
	);
};

export default withUIContext(withDataContext(CallToActionSection, ['getGlobalData']), ['winWidth', 'openPopup']);
