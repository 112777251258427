'use client';

import { useCallback, useEffect, useRef } from 'react';
import { gsap, Power2 } from 'gsap';
import { usePathname, useParams } from 'next/navigation';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import HeroSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import FileSection from '@/components/global/FileSection';
import PopupSubscribePlan from '@/components/global/PopupSubscribePlan';

const HeroSection = ({ title, description, call_to_action_url, file, openPopup }) => {
	//! Refs
	const heroBtnRef = useRef([]);
	const heroFileRef = useRef([]);
	const heroTextRef = useRef([]);
	const heroTitleRef = useRef([]);
	const heroContainerRef = useRef([]);
	const heroFileWrapperRef = useRef([]);
	// const heroFileInnerWrapperRef = useRef([]);

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Handle Scroll
	const handleScroll = useCallback(() => {
		const scrollY = window.scrollY;
		const translateYSize = scrollY / -45;

		gsap.to(heroFileRef.current, {
			duration: 0.6,
			ease: 'easeOut',
			css: {
				transform: `translate(0, ${translateYSize}%)`,
			},
		});
	}, []);

	//! Scroll Event Listener
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	//! Animations
	useEffect(() => {
		gsap.to(heroTitleRef.current, { scale: 1, duration: 0.7, opacity: 1, delay: 0.1, ease: Power2.easeOut });

		gsap.to(heroTextRef.current, { opacity: 1, duration: 0.7, delay: 0.3, ease: Power2.easeOut });

		gsap.to(heroBtnRef.current, { scale: 1, duration: 0.7, delay: 0.5, ease: Power2.easeOut });

		gsap.to(heroFileWrapperRef.current, { opacity: 1, duration: 0.7, delay: 0.7, ease: Power2.easeOut });

		// gsap.to(heroFileInnerWrapperRef.current, { scale: 1, duration: 1, delay: 0.7, ease: Power2.easeOut });
	}, [pathname, params.lang]);

	return (
		<HeroSectionStyle ref={heroContainerRef}>
			<Container row className="hero-wrapper">
				<div className="col-7 col-t-12 row align-center">
					<Text
						tag={`h1`}
						ref={heroTitleRef}
						className={`col-12 hero-title h3 font-argent royal-blue-1000 letter-spacing-h3`}>
						{title}
					</Text>

					<Text
						ref={heroTextRef}
						className={`col-8 col-t-12 hero-description p p4 font-avenir-regular royal-blue-1000 pre-wrap`}>
						{description}
					</Text>

					<div className={`empty-col col-2 col-t-12`} />

					<div className={`col-12 hero-btn-wrapper`}>
						<div
							ref={heroBtnRef}
							className={`hero-btn-inner-wrapper`}>
							<Button
								url='/analytics'
								className={`primary`}
								text='GO TO ANALYTICS'
								// onClick={() => openPopup(<PopupSubscribePlan />)}
							/>
						</div>
					</div>
				</div>
				<div className="col-5 col-t-12 hero-file-wrapper" ref={heroFileWrapperRef}>
					<FileSection
							priority
							objectFit="contain"
							file={file}
							ref={heroFileRef}
					/>
				</div>
			</Container>

			{/* <div
				ref={heroFileWrapperRef}
				className={`hero-file-wrapper`}>
				<div
					ref={heroFileInnerWrapperRef}
					className={`hero-file-inner-wrapper`}>
					<FileSection
						priority
						file={file}
						ref={heroFileRef}
					/>
				</div>
			</div> */}
		</HeroSectionStyle>
	);
};

export default withUIContext(HeroSection, ['openPopup']);
