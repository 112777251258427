import styled from 'styled-components';

const BenefitsSectionStyle = styled.section`
	--bfSectionPadTB: var(--sp23x);

	--bfContentItemsMTop: var(--sp8x);
	--checkIconSize: var(--sp4x);
	--bfItemTextDescDistance: var(--sp2-5x);

	background: radial-gradient(circle at 100%, #1d3657 -10%, #0D1724 30%);
	padding-top: 118px;
	padding-bottom: var(--bfSectionPadTB);
	margin-bottom: 0;
	.button-wrapper {
		.primary {
			margin: auto;
			margin-top: 24px;
			&:hover .link-item, .link-item {
				border: 1px solid #fff;
			}
		}
	}
	.bf-content-title {
		margin-bottom: 60px;
	}
	.ant-tabs-tabpane {
		display: none;
		&-active {
			display: block;
		}
		.row {
			margin: 0;
		}
	}
	.ant-tabs-ink-bar, .ant-tabs-dropdown, .ant-tabs-nav-operations {
		display: none;
	}
	.ant-tabs-nav-list {
		margin-bottom: 60px;
		font-family: var(--argent);
		font-style: normal;
		font-weight: 250;
		font-size: 28px;
		line-height: 120%;

		display: flex;
		justify-content: space-between;
		align-items: center;

		text-align: center;
		letter-spacing: 0.03em;
		text-transform: capitalize;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		transform: none!important;
		.ant-tabs-tab {
			color: rgba(255, 255, 255, 0.7);
			cursor: pointer;
			border-bottom: 2px solid transparent;
			padding: 34px 0;
			width: 100%;
			transition: .2s all;
			&:hover, &.ant-tabs-tab-active {
				color: #FFFFFF;
				border-bottom: 2px solid #FFFFFF;
			}
		}
	}

	.bf-content-description {
		margin-top: var(--titleToTextDistance);
	}

	.file-container {
		.image-cont,
		.video-cont {
			--fileHeight: 122.76%;
		}
	}

	.bf-content-items-wrapper {
		margin-top: 60px;
		.editor-wrapper {
			p, li {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: rgba(255, 255, 255, 0.8);
			}
			ul {
				padding-inline-start: 0;
			}
			li {
				padding-left: 16px;
				&:not(:last-child) {
					margin-bottom: 32px;
				}
				&::marker {
					content: "•";
				}
			}
		}
	}

	.bf-content-item-wrapper {
		width: 45%;
		margin-bottom: var(--bfContentItemsMTop);
		padding: 0 calc(var(--gutter) / 2);
	}

	.check-icon-block {
		max-width: var(--checkIconSize);
		width: 100%;
		height: var(--checkIconSize);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--white1000);
		border-radius: 50%;
	}

	i {
		display: flex;
		justify-content: center;
		align-items: center;

		&:before {
			font-size: var(--p4);
		}
	}

	.bf-content-item-title,
	.bf-content-item-desc {
		margin-top: var(--bfItemTextDescDistance);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--bfSectionPadTB: var(--sp18x);

		--bfContentItemsMTop: var(--sp6x);
		--checkIconSize: var(--sp3x);
		--bfItemTextDescDistance: var(--sp2x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--bfSectionPadTB: var(--sp17x);

		--bfContentItemsMTop: var(--sp6x);
		--checkIconSize: var(--sp3x);
		--bfItemTextDescDistance: var(--sp2x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--bfSectionPadTB: var(--sp13x);

		--bfContentItemsMTop: var(--sp5x);
		--checkIconSize: var(--sp3x);
		--bfItemTextDescDistance: var(--sp2x);
	}

	/* //! 1280 */
	@media only screen and (max-width: 1280px) {
		--bfSectionPadTB: var(--sp13x);

		--bfContentItemsMTop: var(--sp5x);
		--checkIconSize: var(--sp3x);
		--bfItemTextDescDistance: var(--sp2x);

		padding-top: 44px;
		padding-bottom: 56px;
		.bf-content-title {
			margin-bottom: 32px;
		}
		.bf-content-items-wrapper {
			margin-top: 34px;
			.editor-wrapper li {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: rgba(255, 255, 255, 0.8);
			}
		}
		.file-container {
			.image-cont,
			.video-cont {
				--fileHeight: 100%;
			}
		}
		.ant-tabs-content {
			display: none;
		}
		.ant-tabs-nav-list {
			text-align: left;
			flex-direction: column;
			border: 0;
			margin-bottom: 0px;
			.ant-tabs-tab {

				padding: 19px 0;

				.ant-tabs-tab-btn {
					position: relative;
				}
				.ant-tabs-tab-btn::after {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					width: 23px;
					height: 23px;
					bottom: calc(50% - 12px);
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.7'%3e%3cline x1='-4.37114e-08' y1='12.5' x2='23' y2='12.5' stroke='white'/%3e%3cline x1='11.5' y1='2.18558e-08' x2='11.5' y2='24' stroke='white'/%3e%3c/g%3e%3c/svg%3e ");
				}
				&-active {
					.ant-tabs-tab-btn::after {
						height: 1px;
						background: #fff;
						bottom: calc(50% + 5px);
						background-image: none;
					}
				}
			}
			.ant-tabs-tab, .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs-tab:hover {
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(4) {
					border-bottom: 0;
				}
			}
			.ant-tabs-tab.ant-tabs-tab-active {
				padding-bottom: 0;
				.ant-tabs-tab-btn {
					padding-bottom: 19px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 0;
				}
			}
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--bfSectionPadTB: var(--sp10x);

		--bfContentItemsMTop: var(--sp5x);
		--checkIconSize: var(--sp3x);
		--bfItemTextDescDistance: var(--sp2x);
		background: radial-gradient(circle at 100%, #1d3657 0%, #0D1724 100%);
		.benefits-file-wrapper {
			margin-top: 16px;
		}

		padding-top: 44px;
		padding-bottom: 56px;
		.bf-content-title {
			margin-bottom: 32px;
		}
		.bf-content-items-wrapper {
			margin-top: 34px;
			.editor-wrapper li {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: rgba(255, 255, 255, 0.8);
			}
		}
		.file-container {
			.image-cont,
			.video-cont {
				--fileHeight: 100%;
			}
		}
		.ant-tabs-tabpane {
			margin-bottom: 20px;
		}
		.ant-tabs-content {
			display: none;
		}
		.ant-tabs-nav-list {
			text-align: left;
			flex-direction: column;
			border: 0;
			margin-bottom: 0px;
			.ant-tabs-tab {

				padding: 19px 0;

				.ant-tabs-tab-btn {
					position: relative;
				}
				.ant-tabs-tab-btn::after {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					width: 23px;
					height: 23px;
					bottom: calc(50% - 12px);
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.7'%3e%3cline x1='-4.37114e-08' y1='12.5' x2='23' y2='12.5' stroke='white'/%3e%3cline x1='11.5' y1='2.18558e-08' x2='11.5' y2='24' stroke='white'/%3e%3c/g%3e%3c/svg%3e ");
				}
				&-active {
					.ant-tabs-tab-btn::after {
						height: 1px;
						background: #fff;
						bottom: calc(50% + 5px);
						background-image: none;
					}
				}
			}
			.ant-tabs-tab, .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs-tab:hover {
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(4) {
					border-bottom: 0;
				}
			}
			.ant-tabs-tab.ant-tabs-tab-active {
				padding-bottom: 0;
				.ant-tabs-tab-btn {
					padding-bottom: 19px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 0;
				}
			}
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--bfSectionPadTB: var(--sp6x);

		--bfContentItemsMTop: var(--sp3x);
		--checkIconSize: var(--sp2-5x);
		--bfItemTextDescDistance: var(--sp1x);
		background: radial-gradient(circle at 100%, #1d3657 0%, #0D1724 100%);
		.benefits-file-wrapper {
			margin-top: 16px;
		}
		.ant-tabs-tabpane {
			margin-bottom: 20px;
		}
		padding-top: 44px;
		padding-bottom: 56px;
		.bf-content-title {
			margin-bottom: 32px;
		}
		.bf-content-items-wrapper {
			margin-top: 34px;
			.editor-wrapper li {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: rgba(255, 255, 255, 0.8);
			}
		}
		.file-container {
			.image-cont,
			.video-cont {
				--fileHeight: 100%;
			}
		}
		.ant-tabs-content {
			display: none;
		}
		.ant-tabs-nav-list {
			text-align: left;
			flex-direction: column;
			border: 0;
			margin-bottom: 0px;
			.ant-tabs-tab {

				padding: 19px 0;

				.ant-tabs-tab-btn {
					position: relative;
				}
				.ant-tabs-tab-btn::after {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					width: 23px;
					height: 23px;
					bottom: calc(50% - 12px);
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.7'%3e%3cline x1='-4.37114e-08' y1='12.5' x2='23' y2='12.5' stroke='white'/%3e%3cline x1='11.5' y1='2.18558e-08' x2='11.5' y2='24' stroke='white'/%3e%3c/g%3e%3c/svg%3e ");
				}
				&-active {
					.ant-tabs-tab-btn::after {
						height: 1px;
						background: #fff;
						bottom: calc(50% + 5px);
						background-image: none;
					}
				}
			}
			.ant-tabs-tab, .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs-tab:hover {
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(4) {
					border-bottom: 0;
				}
			}
			.ant-tabs-tab.ant-tabs-tab-active {
				padding-bottom: 0;
				.ant-tabs-tab-btn {
					padding-bottom: 19px;
					border-bottom: 1px solid rgba(255, 255, 255, 0.15);
				}
				&:nth-child(1),&:nth-child(2),&:nth-child(3) {
					border-bottom: 0;
				}
			}
		}
	}
`;
export default BenefitsSectionStyle;
