import styled from 'styled-components';

const PastPresentSectionStyle = styled.section`
    --colorGreen: #308850;
    --blue: #000430;
    padding: var(--sp15x) 0;
    color: #0D1724;
    .carousel .carousel__slide--visible,
    .carousel__track,
    .carousel__viewport {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    }
    .container.row {
        flex-wrap: wrap-reverse;
    }
    .btn-text a {
        min-width: 190px;
        color: #0D1724;
        border: 1px solid #0D1724;
    }
    .btn-text.primary a {
        background-color: #000430;
        color: white;
        border: 1px solid #000430;
    }
    .center {
        align-content: center;
    }
    .title {
        margin-bottom: var(--sp3x);
        &-mobile {
            display: none;
            margin-bottom: var(--sp5x);
            text-align: center;
            font-size: 32px;
            letter-spacing: 0.03em;
        }
    }
    .text {
        line-height: 26px;
    }
    .buttons {
        margin-top: 55px;
    }
    .slider {
        height: 100%;
        &-wrapper {
            position: relative;
        }
        &-slide {
            display: flex;
        }
        &-divider {
            width: 1px;
            background: var(--royalBlue1000);
            margin: 50px var(--sp2x);

        }
        &-title {
            position: relative;
            width: fit-content;
            margin: auto;
            line-height: 32px;
            margin-bottom: var(--sp3x);
            color: black;
            padding-left: 19px;
            &.past {
                &::before {
                color: #A93B44;
                }
            }
            &.present::before {
                color: var(--colorGreen);
            }
            &::before {
                content: "⬤";
                position: absolute;
                font-size: 10px;
                line-height: 32px;
                left: 0;
            }
            span {
                font-weight: 300;
            }
        }
        &-percent {
            line-height: 44px;
            font-size: 42px;
            margin-bottom: 4px;
        }
        &-text {
            line-height: 21.6px;
            line-height: 1.2;
            letter-spacing: 0.02em;
            opacity: 80%;
        }
    }
    .slick {
        &-arrow {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            height: 24px;
            &.prev {
                left: var(--colPadding);
            }
            &.next {
                right: var(--colPadding);
            }
        }
        &-slider {
            overflow: hidden
        }
        &-track {
            display: flex;
        }
        &-dots {
            margin: auto;
            margin-top: 16px;
            width: fit-content;
            height: 24px;
            li {
                display: inline-block;
                position: relative;
                width: 16px;
                height: 16px;
                margin: 4px 2px;
                border-radius: 50%;
                border: 2px solid var(--blue);
                box-sizing: border-box;
                opacity: .3;
                background: transparent;
                transition: .2s opacity, .2s background;
                &:not(:last-child) {
                    margin-right: 4px;
                }
                &.slick-active {
                    background: var(--blue);
                    border: transparent;
                    opacity: 1;
                }
            }
            button {
                position: absolute;
                left: -4px;
                top: -4px;
                width: 20px;
                height: 20px;
                visible: hidden;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-bottom: var(--sp3x);
        &-past {
            
            
        }
        &-present {
            width: 200px;
            height: 200px;
            position: relative;
            border-radius: 100%;
            border: 35px solid var(--colorGreen);
        }
    }
    @media (max-width:1200px) {
        padding: var(--sp10x) 0;
        .title {
            display: none;
            &-mobile {
                font-size: 64px;
                line-height: 1.2;
                letter-spacing: 0.03em;
                display: block;
            }
        }
        .circle {
            margin-bottom: var(--sp2x);
        }
        .slider {
            &-wrapper {
                margin-bottom: var(--sp3x);
            }
            &-title {
                font-size: 24px;
                margin-bottom: var(--sp2x);
            }
        }
        .slick-arrow {
            display: none;
        }
        .buttons {
            margin-top: var(--sp3x);
            justify-content: center;
        }
    }
    @media (max-width: 768px) {
        .row .col-t-12 {
            padding: 0;
        }
        .title-mobile {
            font-size: 32px;
        }
        .circle {
            width: 100px;
            height: 100px;
            &-present {
                border-width: 18px;
            }
        }
        .slider {

            &-title {
                font-size: 14px;
                margin-bottom: var(--sp2x);
                line-height: 20px;
                padding-left: 14px;
                &::before {
                    line-height: 20px;
                }
            }
            &-percent {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 4px;
            }
            &-text {
                font-size: 14px;
                line-height: 20px;
            }
            &-divider {
                margin: 0 var(--sp1x);
            }
        }
        .buttons {
            gap: var(--sp2x);
            .btn-text {
                width: 100%
            }
        }
    }
`;
export default PastPresentSectionStyle;
