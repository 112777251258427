import styled from 'styled-components';

const HeroSectionStyle = styled.section`
	--heroFileProportion: 110%;

	padding-top: var(--headerToHeroSectionDistance);
	margin-bottom: var(--sectionDistance);
	.align-center {
		align-content: center;
	}
	.hero-title {
		transform: scale(1);
		padding-top: 36px;
	}

	.hero-title,
	.hero-description,
	.hero-file-wrapper {
		margin-top: 0;
		opacity: 0;
	}

	.hero-btn-inner-wrapper {
		transform: scale(0);
	}

	.hero-file-wrapper {
		overflow: hidden;
		isolation: isolate;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
	}

	.hero-file-inner-wrapper {
		transform: scale(1);
	}

	.hero-description {
		margin-top: var(--titleToTextDistance);
	}

	.hero-btn-wrapper {
		display: flex;
		align-items: center;
		justify-content: start;
		margin-top: var(--textToBtnDistance);
	}

	// .hero-file-wrapper {
	// 	margin-top: var(--btnToBannerDistance);
	// }

	.file-container {
		overflow: hidden;
		isolation: isolate;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);

		.image-cont,
		.video-cont {
			--fileHeight: var(--heroFileProportion);

			will-change: transform;

			img,
			video {
				// height: 140% !important;
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		// --heroFileProportion: 42.2%;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		.file-container .image-cont {
			--fileHeight: 300px;
		}
		.file-container {
			height: 300px;
		}
		.hero-file-wrapper {
			align-content: center;
		}
		.hero-wrapper>.row {
			flex-direction: column-reverse;
		}
		// --heroFileProportion: 45.02%;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		.file-container .image-cont {
			--fileHeight: 300px;
		}
		.file-container {
			height: 300px;
		}
		.hero-file-wrapper {
			align-content: center;
		}
		.hero-wrapper>.row {
			flex-direction: column-reverse;
		}
		// --heroFileProportion: 45.7%;
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		.file-container .image-cont {
			--fileHeight: 300px;
		}
		.file-container {
			height: 300px;
		}
		.hero-file-wrapper {
			align-content: center;
		}
		.hero-title {
			transform: scale(1);
		}
		.hero-wrapper>.row {
			flex-direction: column-reverse;
		}
		// --heroFileProportion: 100%;
	}
`;
export default HeroSectionStyle;
