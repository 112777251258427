import styled from 'styled-components';

const StatisticsSectionStyle = styled.section`
	--stContentDescMTop: var(--sp4x);
	--stContentBtnMTop: var(--sp5x);

	--stItemPad: var(--sp6x);
	--stItemDescMTop: 0;

	--darkSectionPadding: var(--sp23x) 0;

	.first-item {
		.st-item-description {
			margin-top: var(--sp3x);
		}
	}

	&.dark {
        padding: var(--darkSectionPadding);
		background-color: var(--royalBlue1000);

		.st-content-title,
		.st-content-description,
		.st-item-title,
		.st-item-description {
			color: var(--white1000);
		}

		.st-item-absolute-horizontal-line,
		.st-item-absolute-vertical-line,
		.st-first-item-absolute-horizontal-line {
			background-color: var(--white1000);
		}
	}

	.st-content-description {
		margin-top: var(--stContentDescMTop);
	}

	.st-content-btn-wrapper {
		width: fit-content;
		margin-top: var(--stContentBtnMTop);
	}

	.st-items-container {
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}

	.st-first-item-absolute-horizontal-line {
		display: block;
		width: 0;
		height: 1px;
		background-color: var(--royalBlue1000);
	}

	.st-item-absolute-horizontal-line {
		display: block;
		position: absolute;
		width: 0;
		height: 1px;
		left: 0;
		background-color: var(--royalBlue1000);
	}

	.st-item-absolute-vertical-line {
		display: block;
		position: absolute;
		width: 1px;
		height: 0;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: var(--royalBlue1000);
	}

	.st-item-wrapper {
		width: 50%;

		&.first-item {
			width: 100%;
		}
	}

	.st-item-inner-wrapper {
		padding: var(--stItemPad);
		height: 100%;
	}

	.st-item-description {
		margin-top: var(--stItemDescMTop);
	}
	
	.st-item-title {
		white-space: nowrap;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--stContentDescMTop: var(--sp3x);
		--stContentBtnMTop: var(--sp4x);

		--stItemPad: var(--sp5x);

		--darkSectionPadding: var(--sp18x) 0;
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--stContentDescMTop: var(--sp3x);
		--stContentBtnMTop: var(--sp4x);

		--stItemPad: var(--sp4x);

		--darkSectionPadding: var(--sp17x) 0;
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--stContentDescMTop: var(--sp3x);
		--stContentBtnMTop: var(--sp4x);

		--stItemPad: var(--sp3x);

		--darkSectionPadding: var(--sp13x) 0;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--stContentDescMTop: var(--sp3x);
		--stContentBtnMTop: var(--sp4x);

		--stItemPad: var(--sp3x);

		--darkSectionPadding: var(--sp13x) 0;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--stContentDescMTop: var(--sp3x);
		--stContentBtnMTop: var(--sp3x);

		--stItemPad: var(--sp2-5x);

		--darkSectionPadding: var(--sp10x) 0;

		.statistics-analytics-wrapper {
			margin-top: var(--sp5x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--stContentDescMTop: var(--sp2x);
		--stContentBtnMTop: var(--sp3x);

		--stItemPad: var(--sp2x);

		--darkSectionPadding: var(--sp6x) 0;

		.statistics-analytics-wrapper {
			margin-top: var(--sp5x);
		}
	}
`;
export default StatisticsSectionStyle;
