'use client';

//* Style
import HomePageStyle from '@/styles/pages/HomePageStyle';

//* Components
import Page from '@/components/global/Page';
import HeroSection from './components/HeroSection';
import InfoSection from './components/InfoSection';
import PricingSection from './components/PricingSection';
import BenefitsSection from './components/BenefitsSection';
// import SolutionSection from './components/SolutionSection';
// import HowItWorksSection from './components/HowItWorksSection';
import StatisticsSection from '@/components/sections/StatisticsSection';
import CallToActionSection from '@/components/sections/CallToActionSection';
import PastPresentSection from './components/PastPresentSection';

export default function HomeTemplate({ pageData }) {
	return (
		<Page className={`home`}>
			<HomePageStyle>
				<HeroSection {...pageData.hero} />

				<InfoSection {...pageData.info} />

				<StatisticsSection />

				<BenefitsSection {...pageData.benefits} />

				<PastPresentSection />
				{/* <HowItWorksSection
					items={pageData.how_it_works.items}
					{...pageData.how_it_works}
				/> */}

				<PricingSection {...pageData.pricing} />

				<CallToActionSection />
			</HomePageStyle>
		</Page>
	);
}
