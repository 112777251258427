import styled from 'styled-components';

const PricingSectionStyle = styled.section`
	--prSectionPadTB: var(--sp23x);
    background: radial-gradient(circle at 30% 60%, #21416A 0%, #0D1724 36%);
	padding-top: var(--prSectionPadTB);
	padding-bottom: 0;
	margin-bottom: var(--sectionDistance);
	.file-container {
		overflow: hidden;
		position: relative;
		&::before {
			z-index: 1;
			content: "";
			display: block;
			position: absolute;
			bottom: -50px;
			width: 100%;
			background: rgba(13, 23, 36, 0.6);
			filter: blur(100px);
			height: 100px;
		}
	}
	.pricing-description {
		margin-top: var(--titleToTextDistance);
	}

	.pricing-file-wrapper {
		margin-top: var(--textToBannerDistance);
	}

	.pricing-buttons-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-top: var(--textToBtnDistance);
		margin-bottom: calc(var(--gutter) / -1);
	}

	.p-btn-block {
		padding: 0 calc(var(--gutter) / 2);
		margin-bottom: var(--gutter);
	}

	.file-container {
		.image-cont,
		.video-cont {
			--fileHeight: 59.76%;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--prSectionPadTB: var(--sp18x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--prSectionPadTB: var(--sp17x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--prSectionPadTB: var(--sp13x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--prSectionPadTB: var(--sp13x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--prSectionPadTB: var(--sp10x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--prSectionPadTB: var(--sp6x);
	}
`;
export default PricingSectionStyle;
