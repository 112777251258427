import styled from 'styled-components';

const PopupSubscribeStyle = styled.div`
	--descriptionMarginTopBottom: var(--sp4x) 0 var(--sp7x) 0;
	--subscribeButtonMarginTop: var(--sp7x);

	.subscribe-title {
		text-align: center;
	}

	.subscribe-description {
		text-align: center;
		margin: var(--descriptionMarginTopBottom);
	}

	.subscribe-text {
		margin-top: var(--subscribeButtonMarginTop);
		text-align: center;
	}

	.subscribe-button {
		margin-top: var(--subscribeButtonMarginTop);
		margin-left: auto;
		margin-right: auto;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--subscribeButtonMarginTop: var(--sp5x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--subscribeButtonMarginTop: var(--sp5x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--subscribeButtonMarginTop: var(--sp5x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
		--subscribeButtonMarginTop: var(--sp4x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
		--subscribeButtonMarginTop: var(--sp4x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
		--subscribeButtonMarginTop: var(--sp4x);
	}
`;

export default PopupSubscribeStyle;
