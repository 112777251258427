'use client';

import { useEffect, useRef } from 'react';
import { gsap, Power1, Power2 } from 'gsap';
import { usePathname, useParams } from 'next/navigation';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';
import Container from '@/components/global/Container';
import FileSection from '@/components/global/FileSection';
import Editor from '@/components/global/Editor';
import { Tabs } from 'antd';

//* Style
import BenefitsSectionStyle from './style';
import Button from '@/components/global/Button';

const BenefitsSection = ({ title, description, file, items, winWidth }) => {
	//! Refs
	const benefitsContainerRef = useRef([]);
	const benefitsTitleRef = useRef([]);
	const benefitsTextRef = useRef([]);
	const bfContentItemTitleRefs = useRef([]);
	const bfContentItemDescRefs = useRef([]);
	const bfContentFileRef = useRef([]);

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Active Section
	const activeSection = useActiveSection(benefitsContainerRef, 100);

	const onChange = (key, noScroll) => {
		let item = document.querySelector(`.ant-tabs-tabpane[aria-labelledby$='${key}']`),
		   items = document.querySelector(`.ant-tabs-tabpane`);
		let tab = document.querySelector(`.ant-tabs-tab[data-node-key="${key}"]`);
		let itemsContainer = document.querySelector('.ant-tabs-content');
		if (window.innerWidth < 1280) {
			if (item) {
				if (tab.classList.contains('ant-tabs-tab-active')) {
					tab.classList.remove('ant-tabs-tab-active')
					itemsContainer.append(item);
				}
				else {
					tab.classList.add('ant-tabs-tab-active')
					tab.append(item);
				}
			}
		}
		else {
			itemsContainer.append(items)
		}
	};

	const Component = (content,file) => {
		return (
			<div className='row'>
				
					<div className={`benefits-content-wrapper col-6 col-t-12`}>
						<div className={`bf-content-items-wrapper`}>
							<Editor data={content} />
						</div>
					</div>

					<div className={`benefits-empty-col col-1 col-t-12`} />

					<div className={`benefits-file-wrapper col-5 col-t-12`}>
						{file ? (
							<FileSection
								file={file}
								objectFit={`contain`}
							/>
						) : null}
					</div>
			</div>
		)
	}

	items.map((item,i ) => {
        item.key = i
        item.label = item.title
        item.children = Component(item.description, item.file)
		item.forceRender = true
    });

	//! Animations
	useEffect(() => {
		onChange(0)
		let el = document.querySelector('.ant-tabs')
		el.addEventListener("touchmove", function (event) { if (event.cancelable) { event.stopPropagation() } }, true);
		// if (activeSection) {
		// 	gsap.to(benefitsTitleRef.current, {
		// 		scale: 1,
		// 		duration: 0.8,
		// 		opacity: 1,
		// 		delay: 0.1,
		// 		ease: Power2.easeOut,
		// 	});

		// 	gsap.to(bfContentFileRef.current, {
		// 		opacity: 1,
		// 		duration: 0.8,
		// 		delay: 0.1,
		// 		ease: Power2.easeOut,
		// 	});

		// 	gsap.to(benefitsTextRef.current, {
		// 		y: 0,
		// 		delay: 0.2,
		// 		duration: 0.8,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});

		// 	gsap.to(bfContentItemTitleRefs.current, {
		// 		y: 0,
		// 		stagger: 0.1,
		// 		delay: 0.2,
		// 		duration: 0.8,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});

		// 	gsap.to(bfContentItemDescRefs.current, {
		// 		y: 0,
		// 		stagger: 0.1,
		// 		delay: 0.2,
		// 		duration: 0.8,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});
		// } else {
		// 	gsap.to(benefitsTitleRef.current, {
		// 		scale: winWidth >= 768 ? 1.2 : 1.1,
		// 		duration: 0,
		// 		opacity: 0,
		// 		delay: 0,
		// 	});

		// 	gsap.to(bfContentFileRef.current, {
		// 		opacity: 0,
		// 		duration: 0,
		// 		delay: 0,
		// 	});

		// 	gsap.to(benefitsTextRef.current, {
		// 		y: '100%',
		// 		duration: 0.8,
		// 		delay: 0.2,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});

		// 	gsap.to(bfContentItemTitleRefs.current, {
		// 		y: '120%',
		// 		stagger: 0.1,
		// 		duration: 0.8,
		// 		delay: 0.2,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});

		// 	gsap.to(bfContentItemDescRefs.current, {
		// 		y: '120%',
		// 		stagger: 0.1,
		// 		delay: 0.2,
		// 		duration: 0.8,
		// 		// autoRound: false,
		// 		ease: Power1.easeOut,
		// 	});
		// }
	}, [pathname, params.lang, activeSection, winWidth]);

	return (
		<BenefitsSectionStyle>
			<Container
				row
				ref={benefitsContainerRef}>
				<div className={`benefits-content-wrapper benefits-header col-12 col-t-12`}>
					<Text
						
						className={`bf-content-title h3 font-argent white-1000 letter-spacing-h3 capitalize`}>
						{title}
					</Text>
                    <Tabs animated={false} items={items} onTabClick={onChange} />
					<div className='button-wrapper'>
						<Button className='primary' url='/product'>
							HOW IT WORKS
						</Button>
					</div>
                </div>
			</Container>
		</BenefitsSectionStyle>
	);
};

export default withUIContext(BenefitsSection, ['winWidth']);
