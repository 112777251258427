'use client';

import { useEffect, useRef } from 'react';
import { gsap, Power2 } from 'gsap';
import { usePathname, useParams } from 'next/navigation';

//* Style
import PricingSectionStyle from './style';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* HOC's
import { withDataContext, withUIContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import FileSection from '@/components/global/FileSection';
import PopupSubscribePlan from '@/components/global/PopupSubscribePlan';

const PricingSection = ({ title, description, file, getGlobalData, winWidth, openPopup }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Refs
	const pricingContainerRef = useRef([]);
	const pricingTitleRef = useRef([]);
	const pricingTextRef = useRef([]);
	const pricingButtonsRef = useRef([]);

	//! Active Section
	const activeSection = useActiveSection(pricingContainerRef, 100);

	//! Title Animation
	useEffect(() => {
		if (activeSection) {
			gsap.to(pricingTitleRef.current, { scale: 1, duration: 0.7, opacity: 1, delay: 0.1, ease: Power2.easeOut });

			gsap.to(pricingTextRef.current, { opacity: 1, duration: 0.7, delay: 0.3, ease: Power2.easeOut });

			gsap.to(pricingButtonsRef.current, { opacity: 1, duration: 0.7, delay: 0.5, ease: Power2.easeOut });
		} else {
			gsap.to(pricingTitleRef.current, { scale: winWidth >= 768 ? 0.9 : 0.9, duration: 0, opacity: 0, delay: 0 });

			gsap.to(pricingTextRef.current, { opacity: 0, duration: 0, delay: 0 });

			gsap.to(pricingButtonsRef.current, { opacity: 0, duration: 0, delay: 0 });

			gsap.killTweensOf([pricingTitleRef.current, pricingTextRef.current, pricingButtonsRef.current]);
		}
	}, [pathname, params.lang, activeSection, winWidth]);

	return (
		<PricingSectionStyle>
			<Container
				row
				ref={pricingContainerRef}>
				<div className={`empty-col col-1 col-t-12`} />

				<Text
					ref={pricingTitleRef}
					className={`col-10 col-t-12 pricing-title h3 font-argent white-1000 letter-spacing-h3 text-center`}>
					{title}
				</Text>

				<div className={`empty-col col-1 col-t-12`} />

				<div className={`empty-col col-2 col-t-12`} />

				<Text
					ref={pricingTextRef}
					className={`col-8 col-t-12 pricing-description p p5 font-avenir-regular white-1000 text-center`}>
					{description}
				</Text>

				<div className={`empty-col col-2 col-t-12`} />

				<div
					ref={pricingButtonsRef}
					className={`pricing-buttons-wrapper col-12`}>
					{/* <div className={`p-btn-block`}>
						<Button
							text={'seePricing'}
							className={`secondary inverse`}
							url={config.routes.pricing.path}
						/>
					</div> */}

					{/* <div className={`p-btn-block`}>
						<Button
							external
							className={`primary`}
							text={'joinTheWaitList'}
                            onClick={() => openPopup(<PopupSubscribePlan />)}
						/>
					</div> */}
				</div>

				<div className={`empty-col col-1 col-t-12`} />

				<div className={`col-10 col-t-12 pricing-file-wrapper`}>
                    <FileSection objectFit={'contain'} file={file} />
				</div>

				<div className={`empty-col col-1 col-t-12`} />
			</Container>
		</PricingSectionStyle>
	);
};

export default withUIContext(withDataContext(PricingSection, ['getGlobalData']), ['winWidth', 'openPopup']);
